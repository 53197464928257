














































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import { DateTime, Duration } from 'luxon'

const DateFormat = 'yyyy-MM-dd'
const DateTimeFormat = 'yyyy-MM-dd HH:mm:ss'

@Component({
  components: {},
})
export default class DebugTimes extends Vue {
  private portalId = 0
  private serviceId = 0
  private placeId = 0
  private week = 0
  private duration = 0
  private slots = []
  private available = []
  private alignment = { isAllow: false, spans: [] }
  private chopped = []
  private date = ''
  private loading = false
  private tab = 'result'
  private zoom = 1
  private responseTimeAlignmentMode = ''
  private timeAlignmentMode = ''
  private timeAlignmentModes = [
    { id: '', name: '(PortalDefault)' },
    { id: 'None', name: 'None' },
    { id: 'ByScheduleAcrossDay', name: 'ByScheduleAcrossDay' },
    { id: 'ByScheduleAcrossPeriod', name: 'ByScheduleAcrossPeriod' },
  ]

  public created(): void {
    this.portalId = parseInt(this.$route.query.portal as string)
    this.serviceId = parseInt(this.$route.query.service as string)
    this.placeId = parseInt(this.$route.query.place as string)
    this.week = parseInt(this.$route.query.week as string)
    if (this.portalId && this.serviceId && this.placeId && this.week) {
      this.updateTimes()
    }
  }

  private selectDate(date: string): void {
    this.date = date
    this.updateTimes()
  }

  private makeSlots(slots) {
    const result = []
    for (let i = 0; i < slots.length; i++) {
      result.push(this.makeSlot(i, slots[i]))
    }
    return result
  }

  private makeSlot(index, slot) {
    slot.key = index
    slot.layers = this.makeLayers(slot.layers)
    slot.layer = this.makeLayer(0, slot.layer)
    return slot
  }

  private makeLayers(layers) {
    const result = []
    for (let i = 0; i < layers.length; i++) {
      result.push(this.makeLayer(i, layers[i]))
    }
    return result
  }

  private makeLayer(index, layer) {
    layer.key = index
    layer.spans = this.makeSpans(layer.spans)
    return layer
  }

  private makeSpans(spans) {
    let result = []
    if (!this.date) {
      result = spans
    } else {
      for (let i = 0; i < spans.length; i++) {
        if (spans[i].start.startsWith(this.date)) {
          result.push(spans[i])
        }
      }
    }
    result.sort((a, b) => {
      return a.start > b.start ? 1 : -1
    })
    for (let i = 0; i < result.length; i++) {
      result[i].key = i
    }
    return result
  }

  private updateTimes(): void {
    this.loading = true
    this.duration = 0
    this.slots = []
    this.available = []
    this.alignment = { isAllow: false, spans: [] }
    this.chopped = []
    let params =
      '' +
      '?serviceId=' +
      this.serviceId +
      '&placeId=' +
      this.placeId +
      '&startTime=' +
      this.startDateTimeString +
      '&endTime=' +
      this.endDateTimeString
    if (this.timeAlignmentMode) {
      params += '&timeAlignmentMode=' + this.timeAlignmentMode
    }
    const url = '/v4/booking/portals/' + this.portalId + '/available-times/debug' + params
    console.log('url: ' + url)
    this.$axios
      .get(url)
      .then((response) => {
        this.duration = response.data.data.duration
        this.slots = this.makeSlots(response.data.data.slots)
        this.available = this.makeSpans(response.data.data.available)
        this.chopped = this.makeSpans(response.data.data.chopped)
        this.alignment = this.makeLayer(0, response.data.data.alignment || { isAllow: false, spans: [] })
        this.responseTimeAlignmentMode = response.data.data.timeAlignmentMode || '(unknown)'
        this.loading = false
      })
      .catch((err) => {
        console.error('err:', err)
        this.loading = false
      })
  }

  private get today(): DateTime {
    const year = DateTime.now().year
    let time = DateTime.fromISO(year + '-01-01T00:00:00')
    for (let i = 0; i < this.week; i++) {
      time = time.plus(Duration.fromISO('P1W'))
    }
    return time
  }

  private get dates(): Array<string> {
    const result = []
    let start = this.startDateTime
    const end = this.endDateTime
    while (start < end) {
      result.push(start.toFormat('yyyy-MM-dd'))
      start = start.plus(Duration.fromISO('P1D'))
    }
    return result
  }

  private get startDateTime(): DateTime {
    if (!this.today) {
      return null
    }
    const time =
      this.today.weekday > 1 ? this.today.minus(Duration.fromISO('P' + (this.today.weekday - 1) + 'D')) : this.today
    return DateTime.fromISO(time.toFormat(DateFormat) + 'T00:00:00', { zone: 'UTC' })
  }

  private get endDateTime(): DateTime {
    if (!this.today) {
      return null
    }
    const time =
      this.today.weekday < 7 ? this.today.plus(Duration.fromISO('P' + (7 - this.today.weekday) + 'D')) : this.today
    return DateTime.fromISO(time.toFormat(DateFormat) + 'T23:59:59', { zone: 'UTC' })
  }

  private get startDateTimeString(): string {
    const time = this.startDateTime
    return time ? time.toFormat(DateTimeFormat) : ''
  }

  private get endDateTimeString(): string {
    const time = this.endDateTime
    return time ? time.toFormat(DateTimeFormat) : ''
  }

  private formatSpanTime(input): string {
    const d = DateTime.fromISO(input, { zone: 'UTC' })
    const t = d.setZone('Europe/Copenhagen').toFormat('yyyy-MM-dd HH:mm:ss')
    const parts = t.split(' ')
    const dParts = parts[0].split('-')
    const tParts = parts[1].split(':')
    if (this.date) {
      return tParts[0] + ':' + tParts[1]
    } else {
      return dParts[1] + '-' + dParts[2] + ' ' + tParts[0] + ':' + tParts[1]
    }
  }

  private formatSpan(span): string {
    const start = this.formatSpanTime(span.start)
    const end = this.formatSpanTime(span.end)
    return start + ' - ' + end
  }

  private getSpanStyle(span, isAllow, isAlignment = false): string {
    const m = DateTime.fromISO(span.start.split('T')[0] + 'T04:00:00', { zone: 'UTC' }).toSeconds()
    const s = DateTime.fromISO(span.start, { zone: 'UTC' }).toSeconds()
    const e = DateTime.fromISO(span.end, { zone: 'UTC' }).toSeconds()
    const duration = Math.ceil((e - s) / 60)
    const l = duration * this.zoom
    const t = ((s - m) / 60) * this.zoom
    let col = ''
    if (isAlignment) {
      col = 'background: blue; color: white;'
    } else {
      if (isAllow) {
        col = duration < this.duration ? 'background: yellow;' : 'background: green; color: white;'
      } else {
        col = 'background: red;'
      }
    }
    return (
      'height: ' + l + 'px; top: ' + t + 'px; position: absolute; font-size: 14px; border: 1px solid black; ' + col
    )
  }

  private get columnStyle() {
    return 'height: ' + 800 * this.zoom + 'px'
  }
}
